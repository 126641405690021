// @media screen and (max-width: 1700px) {
//     html {
//         zoom: .8;
//         /*-moz-transform: scale(.8); UGLY rendering in FF => menu bars don't use 100% of screen width
//     -moz-transform-origin: 0 0;*/
//     }
// }

* {
  box-sizing: border-box;
}

body,
html {
  min-height: 100vh;
  font-size: 14px;
  font-family: sans-serif;
  color: #333333;
}

.page-content {
  flex-grow: 1;
  min-height: 100%;
  padding: 20px;
  overflow-x: hidden;
}

app > .k-animation-container.k-animation-container-fixed.k-animation-container-shown, app > .k-animation-container.k-animation-container-fixed {
    margin: 0 0 0 0;
}

.anim02 {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.clickable:hover {
    cursor: pointer;
}

.clickable.underline:hover {
    text-decoration: underline;
}

a, .k-grid .k-grid-content a.clickable, .k-grid .k-grid-content a[href] {
    color: $gold;
    text-decoration: none;
}

.gold {
    color: $gold;
}

.bg-gold {
    color: #ffffff !important;
    border-color: #b89d54 !important;
    background: #b89d54 linear-gradient(rgba(184, 180, 84, 0), rgba(184, 180, 84, 0.2)) !important;
}

strong, b {
    font-weight: 700;
}

p {
    margin: 0 0 12px 0;
}

pre {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    margin: 0;
    font-weight: normal;
    //noinspection CssUnknownProperty
    -moz-font-smoothing: antialiased;
    //noinspection CssUnknownProperty
    -webkit-font-smoothing: antialiased;
    //noinspection CssUnknownProperty
    font-smoothing: antialiased;
}

h1 {
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 12px 0;
    font-weight: 400;
    position: relative;
    text-align: left;
}

h2 {
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 22px;
    margin: 0 0 8px 0;
    font-weight: 400;
    position: relative;
}

h3 {
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin: 0 0 8px 0;
}



h4 {
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin: 0 0 8px 0;
}

.with-header h3 {
    margin-top: -5px;
}

em {
    font-style: normal;
    text-decoration: underline;
}

.block {
    padding: 10px;
}

.block-top {
    padding: 7px 10px 6px;
    color: #999999;
}

.bordered {
    border: 1px solid #dddddd;
    border-radius: 4px;
}

.filled {
    border-radius: 4px;
    background: $grey-background;
}

.with-header {
    background: linear-gradient($light-gold 0%, $light-gold 25px, $grey-background 30px);
}

.with-header-35 {
    background: linear-gradient($light-gold 0%, $light-gold 35px, $grey-background 30px);
}

.with-subheader {
    background: linear-gradient($grey-background 0%, $grey-background 25px, $grey-background 30px);
}
/* Button bar to put above grids */
.button-bar {
    display: flex;
    justify-content: space-between;
    & > div {
        display: inherit;
        gap: 10px;
    }
}

.right {
    justify-content: flex-end;
}

.centered {
    text-align: center;
    justify-content: center;
}

/* Widths & grow */
.w10 {
    width: 10%;
}

.w15 {
    width: 15%;
}

.w20 {
    width: 20%;
}

.w30 {
    width: 30%;
}

.w35 {
    width: 35%;
}

.w40 {
    width: 40%;
}

.w49 {
    width: 49%;
}

.w50 {
    width: 50%;
}

.w60 {
    width: 60%;
}

.w65 {
    width: 65%;
}

.w70 {
    width: 70%;
}

.w80 {
    width: 80%;
}

.w85 {
    width: 85%;
}

.w90 {
    width: 90%;
}

.w100 {
    width: 100%;
}

.mw100 {
    min-width: 100px;
}

.mw150 {
    min-width: 150px;
}

.mw200 {
    min-width: 200px;
}

.mw300 {
    min-width: 300px;
}

.mw350 {
    min-width: 350px;
}

.mw400 {
    min-width: 400px;
}

.mw450 {
    min-width: 450px;
}

.mw500 {
    min-width: 500px;
}

.mw600 {
    min-width: 600px;
}

.mw750 {
    min-width: 750px;
}

.mw900 {
    min-width: 900px;
}

.mw1200 {
    min-width: 1200px;
}

.mw1350 {
    min-width: 1350px;
}

.mw1400 {
    min-width: 1400px;
}

.grow0 {
    flex-grow: 0 !important;
}

.grow1 {
    flex-grow: 1 !important;
}

.grow2 {
    flex-grow: 2 !important;
}

.grow3 {
    flex-grow: 3 !important;
}

.grow4 {
    flex-grow: 4 !important;
}

.grow5 {
    flex-grow: 5 !important;
}
.mh40 {
    max-height: 40px;
}
.mh100 {
    max-height: 100px;
}

.mh120 {
    max-height: 120px;
}

.mh500 {
    max-height: 530px;
}

.h100 {
    height: 100%;
}

.maxw500 {
    max-width: 500px;
}

.wp200 {
    width: 200px !important;
}

.w100-imp {
    width: 100% !important;
}

.email-label {
    width: 218px;
}

/* Flex for forms */
.vertical {
    display: flex;
    flex-flow: column nowrap;
}

.horizontal {
    display: flex;
    flex-flow: row wrap;
}

.space10 {
    margin: -5px;
}

.space10 > * {
    margin: 5px;
}

.space10 > .space10 {
    margin: 0;
}

.space20 {
    margin: -10px;
}

.space20 > * {
    margin: 10px;
}

.form {
    margin: -5px;
}

.form > * {
    margin: 5px;
}

.form label {
    font-weight: bold;
}

.labels-left > * {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.labels-left {
    label {
        margin-right: 5px;
        width: 120px;
        min-width: 120px;
    }

    &.labels-lg {
        label {
            width: 200px;
        }
    }

    &.labels-xl {
        label {
            width: 290px;
        }
    }

    &.labels-sm {
        label {
            width: 70px;
        }
    }

    fibaCheckbox {
        display: inline-block;

        label {
            float: left;
        }
    }
}

.labels-left fibacheckbox label {
    width: auto;
}

.margin-below-10 {
    margin-bottom: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-10 {
    margin: 10px;
}

.fill-all > * {
    flex-grow: 1;
}

.fa {
    &.pw-valid {
        color: forestgreen;
        margin-left: 10px;
        margin-right: 6px;
    }

    &.pw-invalid {
        color: tomato;
        margin-left: 10px;
        margin-right: 6px;
    }

    span {
        font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    }
}

.input-sm {
    input[type=text] {
        width: 100px;
    }
}

.input-md {
    input[type=text] {
        width: 235px;
    }
}

fibaTooltipPasswordValidation {
    float: left;
}

fibaInputPassword {
    flex-grow: 0;
}

fibaInputTextarea.input-width {
    textarea {
        width: 212px;
    }
}

fibaInputText.input-sm {
    input[type=text] {
        width: 50px;
    }
}

fibaInputText.input-lg {
    input[type=text] {
        width: 250px;
    }
}

.input-lg {
    .k-combobox {
        width: 280px;
    }
}

.input-xl {
    fibaselectcompetitionteam, fibaselectpreliminaryroster, fibaselectfinalroster {
        .k-combobox {
            width: 350px;
        }
    }

    fibainputtext {
        input[type=text] {
            width: 300px;
        }
    }
}

fibaTooltipPlayerLicenseRequestNationalities {
    kendo-grid {
        width: 800px;
    }
}

fibaTooltipPlayerLicenseRequestNationalities {
    kendo-grid {
        width: 800px;
    }
}

fibagridcompetitionteamdelegationplayer {
    .k-grid-content tr:last-child td, .k-grid-content-locked tr:last-child td {
        overflow: visible;
    }
}

formregistrationtemplatefieldansweritemgrid {
    kendo-grid {
        width: 560px;
    }
}

fibagridcompetitionteamdelegationcoach {
    .k-grid-content tr:last-child td, .k-grid-content-locked tr:last-child td {
        overflow: visible;
    }
}

fibagridcompetitionteamdelegationaccompanying {
    .k-grid-content tr:last-child td, .k-grid-content-locked tr:last-child td {
        overflow: visible;
    }
}

//.k-grid-content tr:last-child td, .k-grid-content-locked tr:last-child td {
//  overflow: visible;
//}

fibaTreelist, fibaNominationTreelist, fibaClassificationTreelist {
    .in-selected-path > tree-node-wrapper > .node-wrapper > .node-content-wrapper {
        background-color: $blue;
        color: white;
    }

    .node-content-wrapper.node-content-wrapper-active, .node-content-wrapper-active.node-content-wrapper-focused {
        background-color: $gold;
        color: white;
    }

    .node-content-wrapper.node-content-wrapper-active:hover,
    .node-content-wrapper-active.node-content-wrapper-focused:hover,
    .node-content-wrapper:hover {
        background-color: #f7fbff !important;
        color: #333333 !important;
    }

    .node-content-wrapper {
        background-color: $grey;
    }
}

.player-license-reason {
    kendo-combobox, textarea {
        width: 450px;
    }
}

.player-nationalities-popup {
    width: inherit;
    max-width: 80%;
}

hr {
    height: 1px;
    border: none #dddddd;
    background-color: #dddddd;
    color: #dddddd;
}

span, i {
    &.color-red {
        color: tomato;
    }

    &.color-orange {
        color: orange;
    }

    &.color-green {
        color: palegreen;
    }

    &.color-forest-green {
        color: forestgreen;
    }

    &.color-blue {
        color: #2981f5;
    }
}

fibapopupcompetitionteamregistration {
    .k-dialog {
        max-width: 70%;
    }
}

.k-dialog.w-medium {
    width: 400px;
}

.level-0 {
    margin-left: 0;
}

.level-1 {
    margin-left: 20px;
}

.level-2 {
    margin-left: 40px;
}

.level-3 {
    margin-left: 60px;
}

.level-4 {
    margin-left: 80px;
}

.level-5 {
    margin-left: 100px;
}

.level-6 {
    margin-left: 120px;
}

.level-7 {
    margin-left: 140px;
}

.not-validated {
    background-color: lightgray;
}

.partially-validated {
    background-color: #ffb347;
}

.validated {
    background-color: darkseagreen;
}

.color-gold {
    color: $gold;
}

.social-label {
    padding: 0 10px 0px 0px;
    min-width: 20px;
}

.host-other-level {
    color: #bababa;
}

kendo-grid {
    &.condensed {
        &.k-grid td {
            padding: 5px 5px;

            &.centered {
                text-align: center;
            }
        }
    }
}

.message-box p {
    margin-top: 0;
    margin-bottom: 0.75rem;
}

.message-box {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    width: 660px;
}

.message-box.message-info {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.message-box.message-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.font-italic {
    font-style: italic;
}

.flex-auto {
    flex: auto;
}

.bold {
    font-weight: bold;
}

.f400 {
    font-weight : 400;
}

.fw600 {
    font-weight: 600;
}

.color-red {
    color: tomato;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-space-around {
    display: flex;
    justify-content: space-around;
}

.goldImportant {
    color: $gold !important;
}

.information-message {
    color: $gold;
    font-weight: bold;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

div.insurance-policy-coverage-status {
    border: 2px solid grey;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    width: 20px;
    padding: 1px 4px;
    font-weight: bold;
    line-height: 12px;
}

div.insurance-policy-coverage-status.conf {
    border-color: green;
    color: green;
}

div.insurance-policy-coverage-status.notconf {
    border-color: red;
    color: red;
}

.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}

.ml-0 {
    margin-left: 0px;
}

.ml-10 {
    margin-left: 10px;
}

.wp1000 {
    width: 1000px;
}

.wp120 {
    width: 120px;
}

.hp450 {
    height: 450px;
}

.pos-relative {
    position: relative;
}

.pos-absolute-bottom {
    position: absolute !important;
    bottom: 0
}

.flex-centered {
    display: flex;
    align-items: center;
}

.pos-absolute {
    position: absolute !important;
}

.ml-100 {
    margin-left: 100px;
}

.ft13 {
    font-size: 13px;
}
.ft16 {
    font-size: 16px;
}

.check-green {
    color: green;
    text-align: center;
}

.number {
    letter-spacing : 2px,
}

.pseudo-h3 {
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}

.icon-centered {
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.click-to-expand:hover {
    cursor: zoom-in;
}
