@use "sass:math";

@use "../button/_variables.scss" as *;
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Input

/// The default width of the Input components.
/// @group input
$kendo-input-default-width: 100% !default;

/// The border width of the Input components.
/// @group input
$kendo-input-border-width: k-spacing(1px) !default;
$kendo-input-border-height: calc( #{ $kendo-input-border-width } * 2 ) !default;
/// The border radius of the Input components.
/// @group input
$kendo-input-border-radius: null !default;

/// The horizontal padding of the Input components.
/// @group input
$kendo-input-padding-x: k-spacing(3) !default;
/// The horizontal padding of the small Input components.
/// @group input
$kendo-input-sm-padding-x: k-spacing(2) !default;
/// The horizontal padding of the medium Input components.
/// @group input
$kendo-input-md-padding-x: k-spacing(3) !default;
/// The horizontal padding of the large Input components.
/// @group input
$kendo-input-lg-padding-x: k-spacing(4) !default;

/// The vertical padding of input components.
/// @group input
$kendo-input-padding-y: k-spacing(1.5) !default;
/// The vertical padding of the small Input components.
/// @group input
$kendo-input-sm-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium Input components.
/// @group input
$kendo-input-md-padding-y: k-spacing(1.5) !default;
/// The vertical padding of the large Input components.
/// @group input
$kendo-input-lg-padding-y: k-spacing(2) !default;

/// The font family of input components.
/// @group input
$kendo-input-font-family: var( --kendo-font-family, inherit ) !default;

/// The font size of input components.
/// @group input
$kendo-input-font-size: var( --kendo-font-size, inherit )  !default;
/// The font size of the small Input components.
/// @group input
$kendo-input-sm-font-size: var( --kendo-font-size-sm, inherit )!default;
/// The font size of the medium Input components.
/// @group input
$kendo-input-md-font-size: var( --kendo-font-size, inherit )  !default;
/// The font size of the large Input components.
/// @group input
$kendo-input-lg-font-size: var( --kendo-font-size, inherit )  !default;

/// The line height of input components.
/// @group input
$kendo-input-line-height: 1.5 !default;
/// The line height of the small Input components.
/// @group input
$kendo-input-sm-line-height: math.div( 20, 14 ) !default;
/// The line height of the medium Input components.
/// @group input
$kendo-input-md-line-height: var( --kendo-line-height, normal) !default;
/// The line height of the large Input components.
/// @group input
$kendo-input-lg-line-height: var( --kendo-line-height, normal) !default;

/// The sizes map for the Input components.
/// @group input
$kendo-input-sizes: (
    sm: (
        padding-x: $kendo-input-sm-padding-x,
        padding-y: $kendo-input-sm-padding-y,
        font-size: $kendo-input-sm-font-size,
        line-height: $kendo-input-sm-line-height,
        button-padding-x: $kendo-input-sm-padding-y,
        button-padding-y: $kendo-input-sm-padding-y
    ),
    md: (
        padding-x: $kendo-input-md-padding-x,
        padding-y: $kendo-input-md-padding-y,
        font-size: $kendo-input-md-font-size,
        line-height: $kendo-input-md-line-height,
        button-padding-x: $kendo-input-md-padding-y,
        button-padding-y: $kendo-input-md-padding-y
    ),
    lg: (
        padding-x: $kendo-input-lg-padding-x,
        padding-y: $kendo-input-lg-padding-y,
        font-size: $kendo-input-lg-font-size,
        line-height: $kendo-input-lg-line-height,
        button-padding-x: $kendo-input-lg-padding-y,
        button-padding-y: $kendo-input-lg-padding-y
    )
) !default;

/// The background color of the Input components.
/// @group input
$kendo-input-bg: $kendo-component-bg !default;
/// The text color of the Input components.
/// @group input
$kendo-input-text: if($kendo-enable-color-system, k-color( on-app-surface ), $kendo-color-dark) !default;
/// The border color of the Input components.
/// @group input
$kendo-input-border: if($kendo-enable-color-system, k-color( border-alt ), #ced4da) !default;
/// The shadow of the Input components.
/// @group input
$kendo-input-shadow: null !default;

/// The background color of the hovered Input components.
/// @group input
$kendo-input-hover-bg: null !default;
/// The text color of the hovered Input components.
/// @group input
$kendo-input-hover-text: null !default;
/// The border color of the hovered Input components.
/// @group input
$kendo-input-hover-border: $kendo-input-border !default;
/// The shadow of the hovered Input components.
/// @group input
$kendo-input-hover-shadow: null !default;

/// The background color of the focused Input components.
/// @group input
$kendo-input-focus-bg: $kendo-input-bg !default;
/// The text color of the focused Input components.
/// @group input
$kendo-input-focus-text: $kendo-input-text !default;
/// The border color of the focused Input components.
/// @group input
$kendo-input-focus-border: if($kendo-enable-color-system, k-color( primary-emphasis ), k-color-tint( $kendo-color-primary, 50% ) ) !default;
/// The shadow of the focused Input components.
/// @group input
$kendo-input-focus-shadow: 0 0 0 .25rem if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba( $kendo-color-primary, .25 )) !default;

/// The background color of the selected Input components.
/// @group input
$kendo-input-selected-bg: null !default;
/// The text color of the selected Input components.
/// @group input
$kendo-input-selected-text: null !default;

/// The background color of the disabled Input components.
/// @group input
$kendo-input-disabled-bg: null !default;
/// The text color of the disabled Input components.
/// @group input
$kendo-input-disabled-text: null !default;
/// The border color of the disabled Input components.
/// @group input
$kendo-input-disabled-border: null !default;
/// The gradient of the disabled Input components.
/// @group input
$kendo-input-disabled-gradient: null !default;
/// The shadow of the disabled Input components.
/// @group input
$kendo-input-disabled-shadow: null !default;

/// The background color of the outline Input components.
/// @group input
$kendo-input-outline-bg: null !default;
/// The text color of the outline Input components.
/// @group input
$kendo-input-outline-text: $kendo-base-text !default;
/// The border color of the outline Input components.
/// @group input
$kendo-input-outline-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), color-mix(in srgb, $kendo-base-text 50%, transparent)) !default;
/// The background color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-bg: null !default;
/// The text color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-text: null !default;
/// The border color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-border: $kendo-input-outline-border !default;

/// The background color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-bg: null !default;
/// The text color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-text: null !default;
/// The border color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-border: $kendo-input-focus-border !default;
/// The shadow of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-shadow: $kendo-input-focus-shadow !default;

/// The background color of the flat Input components.
/// @group input
$kendo-input-flat-bg: null !default;
/// The text color of the flat Input components.
/// @group input
$kendo-input-flat-text: $kendo-input-text !default;
/// The border color of the flat Input components.
/// @group input
$kendo-input-flat-border: $kendo-input-border !default;

/// The background color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-bg: null !default;
/// The text color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-text: null !default;
/// The border color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-border: $kendo-input-hover-border !default;

/// The background color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-bg: null !default;
/// The text color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-text: null !default;
/// The border color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-border: $kendo-input-focus-border !default;
/// The shadow of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-shadow: $kendo-input-focus-shadow !default;

/// The text color of the Input placeholder.
/// @group input
$kendo-input-placeholder-text: if($kendo-enable-color-system, k-color( subtle ) , $kendo-subtle-text) !default;
/// The opacity of the Input placeholder.
/// @group input
$kendo-input-placeholder-opacity: 1 !default;

/// The color of the Input clear value icon.
/// @group input
$kendo-input-clear-value-text: null !default;
/// The opacity of the Input clear value icon.
/// @group input
$kendo-input-clear-value-opacity: .5 !default;
/// The color of the hovered Input clear value icon.
/// @group input
$kendo-input-clear-value-hover-text: null !default;
/// The opacity of the hovered Input clear value icon.
/// @group input
$kendo-input-clear-value-hover-opacity: 1 !default;

/// The vertical margin of the clear value icon.
/// @group input
$kendo-input-values-margin-y: k-spacing(0.5) !default;
/// The horizontal margin of the clear value icon.
/// @group input
$kendo-input-values-margin-x: $kendo-input-values-margin-y !default;

/// The width of the Input button.
/// @group input
$kendo-input-button-width: unset !default;
/// The border width of the Input button.
/// @group input
$kendo-input-button-border-width: 1px !default;
/// The width of the Input spinner button.
/// @group input
$kendo-input-spinner-width: unset !default;
/// The icon offset of the Input spinner button.
/// @group input
$kendo-input-spinner-icon-offset: null !default;

/// The color of the Input separator.
/// @group input
$kendo-input-separator-text: $kendo-component-border !default;
/// The opacity of the Input separator.
/// @group input
$kendo-input-separator-opacity: .5 !default;

/// The text color of the Input prefix.
/// @group input
$kendo-input-prefix-text: if($kendo-enable-color-system, k-color( subtle ) , $kendo-subtle-text) !default;
/// The text color of the Input suffix.
/// @group input
$kendo-input-suffix-text: if($kendo-enable-color-system, k-color( subtle ) , $kendo-subtle-text) !default;

/// The border color of the invalid Input components.
/// @group input
$kendo-input-invalid-border: $kendo-invalid-border !default;
/// The shadow of the invalid Input components.
/// @group input
$kendo-input-invalid-shadow: $kendo-invalid-shadow !default;

/// The background color of the Picker components.
/// @group picker
$kendo-picker-bg: $kendo-button-bg !default;
/// The text color of the Picker components.
/// @group picker
$kendo-picker-text: $kendo-button-text !default;
/// The border color of the Picker components.
/// @group picker
$kendo-picker-border: $kendo-button-border !default;
/// The gradient of the Picker components.
/// @group picker
$kendo-picker-gradient: $kendo-button-gradient !default;
/// The shadow of the Picker components.
/// @group picker
$kendo-picker-shadow: $kendo-button-shadow !default;

/// The background color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-bg: $kendo-button-hover-bg !default;
/// The text color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-text: $kendo-button-hover-text !default;
/// The border color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-border: $kendo-button-hover-border !default;
/// The gradient of the hovered Picker components.
/// @group picker
$kendo-picker-hover-gradient: $kendo-button-hover-gradient !default;
/// The shadow of the hovered Picker components.
/// @group picker
$kendo-picker-hover-shadow: $kendo-button-hover-shadow !default;

/// The background color of the focused Picker components.
/// @group picker
$kendo-picker-focus-bg: $kendo-button-active-bg !default;
/// The text color of the focused Picker components.
/// @group picker
$kendo-picker-focus-text: $kendo-button-focus-text !default;
/// The border color of the focused Picker components.
/// @group picker
$kendo-picker-focus-border: $kendo-button-focus-border !default;
/// The gradient of the focused Picker components.
/// @group picker
$kendo-picker-focus-gradient: $kendo-button-focus-gradient !default;
/// The shadow of the focused Picker components.
/// @group picker
$kendo-picker-focus-shadow: $kendo-button-focus-shadow !default;

/// The background color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-bg: null !default;
/// The text color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-text: null !default;
/// The border color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-border: null !default;
/// The gradient of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-gradient: null !default;
/// The shadow of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-shadow: null !default;

/// The background color of the outline Picker components.
/// @group picker
$kendo-picker-outline-bg: null !default;
/// The text color of the outline Picker components.
/// @group picker
$kendo-picker-outline-text: $kendo-button-text !default;
/// The border color of the outline Picker components.
/// @group picker
$kendo-picker-outline-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), color-mix(in srgb, $kendo-picker-outline-text 50%, transparent)) !default;
/// The background color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-bg: $kendo-picker-outline-text !default;
/// The text color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-text: if($kendo-enable-color-system, k-color( base-subtle ), k-contrast-color( $kendo-picker-outline-hover-bg )) !default;
/// The border color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-border: $kendo-picker-outline-hover-bg !default;

/// The background color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-bg: null !default;
/// The text color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-text: null !default;
/// The border color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-border: null !default;
/// The shadow of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-shadow: $kendo-picker-focus-shadow !default;

/// The background color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-bg: null !default;
/// The text color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-text: null !default;
/// The border color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-border: null !default;

/// The background color of the flat Picker components.
/// @group picker
$kendo-picker-flat-bg: null !default;
/// The text color of the flat Picker components.
/// @group picker
$kendo-picker-flat-text: $kendo-button-text !default;
/// The border color of the flat Picker components.
/// @group picker
$kendo-picker-flat-border: $kendo-button-border !default;

/// The background color of the flat hovered Picker components.
/// @group picker
$kendo-picker-flat-hover-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), color-mix(in srgb, $kendo-button-text 80%, transparent)) !default;
/// The text color of the flat hovered Picker components.
/// @group picker
$kendo-picker-flat-hover-text: null !default;
/// The border color of the flat hovered Picker components.
/// @group picker
$kendo-picker-flat-hover-border: $kendo-picker-flat-border !default;

/// The background color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-bg: null !default;
/// The text color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-text: null !default;
/// The border color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-border: null !default;
/// The shadow of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-shadow: $kendo-picker-focus-shadow !default;

/// The background color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-bg: null !default;
/// The text color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-text: null !default;
/// The border color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-border: null !default;



@forward "@progress/kendo-theme-default/scss/input/_variables.scss" with (
    $kendo-input-default-width: $kendo-input-default-width,
    $kendo-input-border-width: $kendo-input-border-width,
    $kendo-input-border-height: $kendo-input-border-height,
    $kendo-input-border-radius: $kendo-input-border-radius,
    $kendo-input-padding-x: $kendo-input-padding-x,
    $kendo-input-sm-padding-x: $kendo-input-sm-padding-x,
    $kendo-input-md-padding-x: $kendo-input-md-padding-x,
    $kendo-input-lg-padding-x: $kendo-input-lg-padding-x,
    $kendo-input-padding-y: $kendo-input-padding-y,
    $kendo-input-sm-padding-y: $kendo-input-sm-padding-y,
    $kendo-input-md-padding-y: $kendo-input-md-padding-y,
    $kendo-input-lg-padding-y: $kendo-input-lg-padding-y,
    $kendo-input-font-family: $kendo-input-font-family,
    $kendo-input-font-size: $kendo-input-font-size,
    $kendo-input-sm-font-size: $kendo-input-sm-font-size,
    $kendo-input-md-font-size: $kendo-input-md-font-size,
    $kendo-input-lg-font-size: $kendo-input-lg-font-size,
    $kendo-input-line-height: $kendo-input-line-height,
    $kendo-input-sm-line-height: $kendo-input-sm-line-height,
    $kendo-input-md-line-height: $kendo-input-md-line-height,
    $kendo-input-lg-line-height: $kendo-input-lg-line-height,
    $kendo-input-sizes: $kendo-input-sizes,
    $kendo-input-bg: $kendo-input-bg,
    $kendo-input-text: $kendo-input-text,
    $kendo-input-border: $kendo-input-border,
    $kendo-input-shadow: $kendo-input-shadow,
    $kendo-input-hover-bg: $kendo-input-hover-bg,
    $kendo-input-hover-text: $kendo-input-hover-text,
    $kendo-input-hover-border: $kendo-input-hover-border,
    $kendo-input-hover-shadow: $kendo-input-hover-shadow,
    $kendo-input-focus-bg: $kendo-input-focus-bg,
    $kendo-input-focus-text: $kendo-input-focus-text,
    $kendo-input-focus-border: $kendo-input-focus-border,
    $kendo-input-focus-shadow: $kendo-input-focus-shadow,
    $kendo-input-selected-bg: $kendo-input-selected-bg,
    $kendo-input-selected-text: $kendo-input-selected-text,
    $kendo-input-disabled-bg: $kendo-input-disabled-bg,
    $kendo-input-disabled-text: $kendo-input-disabled-text,
    $kendo-input-disabled-border: $kendo-input-disabled-border,
    $kendo-input-disabled-gradient: $kendo-input-disabled-gradient,
    $kendo-input-disabled-shadow: $kendo-input-disabled-shadow,
    $kendo-input-outline-bg: $kendo-input-outline-bg,
    $kendo-input-outline-text: $kendo-input-outline-text,
    $kendo-input-outline-border: $kendo-input-outline-border,
    $kendo-input-outline-hover-bg: $kendo-input-outline-hover-bg,
    $kendo-input-outline-hover-text: $kendo-input-outline-hover-text,
    $kendo-input-outline-hover-border: $kendo-input-outline-hover-border,
    $kendo-input-outline-focus-bg: $kendo-input-outline-focus-bg,
    $kendo-input-outline-focus-text: $kendo-input-outline-focus-text,
    $kendo-input-outline-focus-border: $kendo-input-outline-focus-border,
    $kendo-input-outline-focus-shadow: $kendo-input-outline-focus-shadow,
    $kendo-input-flat-bg: $kendo-input-flat-bg,
    $kendo-input-flat-text: $kendo-input-flat-text,
    $kendo-input-flat-border: $kendo-input-flat-border,
    $kendo-input-flat-hover-bg: $kendo-input-flat-hover-bg,
    $kendo-input-flat-hover-text: $kendo-input-flat-hover-text,
    $kendo-input-flat-hover-border: $kendo-input-flat-hover-border,
    $kendo-input-flat-focus-bg: $kendo-input-flat-focus-bg,
    $kendo-input-flat-focus-text: $kendo-input-flat-focus-text,
    $kendo-input-flat-focus-border: $kendo-input-flat-focus-border,
    $kendo-input-flat-focus-shadow: $kendo-input-flat-focus-shadow,
    $kendo-input-placeholder-text: $kendo-input-placeholder-text,
    $kendo-input-placeholder-opacity: $kendo-input-placeholder-opacity,
    $kendo-input-clear-value-text: $kendo-input-clear-value-text,
    $kendo-input-clear-value-opacity: $kendo-input-clear-value-opacity,
    $kendo-input-clear-value-hover-text: $kendo-input-clear-value-hover-text,
    $kendo-input-clear-value-hover-opacity: $kendo-input-clear-value-hover-opacity,
    $kendo-input-values-margin-y: $kendo-input-values-margin-y,
    $kendo-input-values-margin-x: $kendo-input-values-margin-x,
    $kendo-input-button-width: $kendo-input-button-width,
    $kendo-input-button-border-width: $kendo-input-button-border-width,
    $kendo-input-spinner-width: $kendo-input-spinner-width,
    $kendo-input-spinner-icon-offset: $kendo-input-spinner-icon-offset,
    $kendo-input-separator-text: $kendo-input-separator-text,
    $kendo-input-separator-opacity: $kendo-input-separator-opacity,
    $kendo-input-prefix-text: $kendo-input-prefix-text,
    $kendo-input-suffix-text: $kendo-input-suffix-text,
    $kendo-input-invalid-border: $kendo-input-invalid-border,
    $kendo-input-invalid-shadow: $kendo-input-invalid-shadow,
    $kendo-picker-bg: $kendo-picker-bg,
    $kendo-picker-text: $kendo-picker-text,
    $kendo-picker-border: $kendo-picker-border,
    $kendo-picker-gradient: $kendo-picker-gradient,
    $kendo-picker-shadow: $kendo-picker-shadow,
    $kendo-picker-hover-bg: $kendo-picker-hover-bg,
    $kendo-picker-hover-text: $kendo-picker-hover-text,
    $kendo-picker-hover-border: $kendo-picker-hover-border,
    $kendo-picker-hover-gradient: $kendo-picker-hover-gradient,
    $kendo-picker-hover-shadow: $kendo-picker-hover-shadow,
    $kendo-picker-focus-bg: $kendo-picker-focus-bg,
    $kendo-picker-focus-text: $kendo-picker-focus-text,
    $kendo-picker-focus-border: $kendo-picker-focus-border,
    $kendo-picker-focus-gradient: $kendo-picker-focus-gradient,
    $kendo-picker-focus-shadow: $kendo-picker-focus-shadow,
    $kendo-picker-disabled-bg: $kendo-picker-disabled-bg,
    $kendo-picker-disabled-text: $kendo-picker-disabled-text,
    $kendo-picker-disabled-border: $kendo-picker-disabled-border,
    $kendo-picker-disabled-gradient: $kendo-picker-disabled-gradient,
    $kendo-picker-disabled-shadow: $kendo-picker-disabled-shadow,
    $kendo-picker-outline-bg: $kendo-picker-outline-bg,
    $kendo-picker-outline-text: $kendo-picker-outline-text,
    $kendo-picker-outline-border: $kendo-picker-outline-border,
    $kendo-picker-outline-hover-bg: $kendo-picker-outline-hover-bg,
    $kendo-picker-outline-hover-text: $kendo-picker-outline-hover-text,
    $kendo-picker-outline-hover-border: $kendo-picker-outline-hover-border,
    $kendo-picker-outline-focus-bg: $kendo-picker-outline-focus-bg,
    $kendo-picker-outline-focus-text: $kendo-picker-outline-focus-text,
    $kendo-picker-outline-focus-border: $kendo-picker-outline-focus-border,
    $kendo-picker-outline-focus-shadow: $kendo-picker-outline-focus-shadow,
    $kendo-picker-outline-hover-focus-bg: $kendo-picker-outline-hover-focus-bg,
    $kendo-picker-outline-hover-focus-text: $kendo-picker-outline-hover-focus-text,
    $kendo-picker-outline-hover-focus-border: $kendo-picker-outline-hover-focus-border,
    $kendo-picker-flat-bg: $kendo-picker-flat-bg,
    $kendo-picker-flat-text: $kendo-picker-flat-text,
    $kendo-picker-flat-border: $kendo-picker-flat-border,
    $kendo-picker-flat-hover-bg: $kendo-picker-flat-hover-bg,
    $kendo-picker-flat-hover-text: $kendo-picker-flat-hover-text,
    $kendo-picker-flat-hover-border: $kendo-picker-flat-hover-border,
    $kendo-picker-flat-focus-bg: $kendo-picker-flat-focus-bg,
    $kendo-picker-flat-focus-text: $kendo-picker-flat-focus-text,
    $kendo-picker-flat-focus-border: $kendo-picker-flat-focus-border,
    $kendo-picker-flat-focus-shadow: $kendo-picker-flat-focus-shadow,
    $kendo-picker-flat-hover-focus-bg: $kendo-picker-flat-hover-focus-bg,
    $kendo-picker-flat-hover-focus-text: $kendo-picker-flat-hover-focus-text,
    $kendo-picker-flat-hover-focus-border: $kendo-picker-flat-hover-focus-border
);
