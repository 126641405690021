@use "../core/_index.scss" as *;
@use "../list/_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;

// CheckBox

/// The border width of the CheckBox.
/// @group checkbox
$kendo-checkbox-border-width: 1px !default;

/// The size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-size: k-spacing(3) !default;
/// The size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-size: k-spacing(4) !default;
/// The size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-size: k-spacing(5) !default;

/// The glyph size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-glyph-size: k-spacing(2.5) !default;
/// The glyph size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-glyph-size: k-spacing(3.5) !default;
/// The glyph size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-glyph-size: k-spacing(4.5) !default;

/// The ripple size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-ripple-size: 300% !default;
/// The ripple size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-ripple-size: 300% !default;
/// The ripple size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-ripple-size: 300% !default;

// A map with the different CheckBox sizes
$kendo-checkbox-sizes: (
    sm: (
        size: $kendo-checkbox-sm-size,
        glyph-size: $kendo-checkbox-sm-glyph-size,
        ripple-size: $kendo-checkbox-sm-ripple-size
    ),
    md: (
        size: $kendo-checkbox-md-size,
        glyph-size: $kendo-checkbox-md-glyph-size,
        ripple-size: $kendo-checkbox-md-ripple-size
    ),
    lg: (
        size: $kendo-checkbox-lg-size,
        glyph-size: $kendo-checkbox-lg-glyph-size,
        ripple-size: $kendo-checkbox-lg-ripple-size
    )
) !default;

/// The background color of CheckBox.
/// @group checkbox
$kendo-checkbox-bg: $kendo-component-bg !default;
/// The text color of CheckBox.
/// @group checkbox
$kendo-checkbox-text: null !default;
/// The border color of CheckBox.
/// @group checkbox
$kendo-checkbox-border: if($kendo-enable-color-system, k-color( border-alt ), if( k-is-light( $kendo-checkbox-bg ), #ced4da, #6c757d )) !default;

/// The background color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-bg: null !default;
/// The text color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-text: null !default;
/// The border color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-border: null !default;

/// The background color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-bg: $kendo-color-primary !default;
/// The color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-legacy( $kendo-checkbox-checked-bg )) !default;
/// The border color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-border: $kendo-checkbox-checked-bg !default;

/// The background color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-bg: $kendo-checkbox-checked-bg !default;
/// The text color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-text: $kendo-checkbox-checked-text !default;
/// The border color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-border: $kendo-checkbox-checked-border !default;

/// The border color of the focused CheckBox.
/// @group checkbox
$kendo-checkbox-focus-border: if($kendo-enable-color-system, k-color( primary-emphasis ), k-try-tint( $kendo-color-primary, 50% )) !default;
/// The box shadow of the focused CheckBox.
/// @group checkbox
$kendo-checkbox-focus-shadow: 0 0 0 .25rem if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba( $kendo-color-primary, .25 )) !default;
/// The border color of the focused and checked CheckBox.
/// @group checkbox
$kendo-checkbox-focus-checked-border: $kendo-checkbox-checked-border !default;
/// The box shadow of the focused and checked CheckBox.
/// @group checkbox
$kendo-checkbox-focus-checked-shadow: $kendo-checkbox-focus-shadow !default;

/// The background color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-bg: null !default;
/// The text color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-text: null !default;
/// The border color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-border: null !default;

/// The background color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-bg: null !default;
/// The text color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-text: null !default;
/// The border color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-border: null !default;

/// The background color of the invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-bg: null !default;
/// The text color of the invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-text: $kendo-invalid-text !default;
/// The border color of the invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-border: $kendo-invalid-border !default;


// CheckBox indicator

/// The type of the CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indicator-type: image !default;

/// The font family of the CheckBox indicator glyph.
/// @group checkbox
$kendo-checkbox-glyph-font-family: "WebComponentsIcons", monospace !default;
/// The glyph of the CheckBox indicator.
/// @group checkbox
$kendo-checkbox-checked-glyph: "\e118" !default;
/// The glyph of the indeterminate CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-glyph: "\e121" !default;

/// The image for a checked CheckBox indicator.
/// @group checkbox
$kendo-checkbox-checked-image: k-escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$kendo-checkbox-checked-text}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") ) !default;
/// The image for a indeterminate CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-image: k-escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$kendo-checkbox-indeterminate-text}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") ) !default;


// CheckBox label

/// The horizontal margin of the CheckBox inside a label.
/// @group checkbox
$kendo-checkbox-label-margin-x: k-spacing(1) !default;


// CheckBox list

/// The spacing between the items in a horizontal CheckBox list.
/// @group checkbox
$kendo-checkbox-list-spacing: k-spacing(4) !default;
/// The horizontal padding of the CheckBox list items.
/// @group checkbox
$kendo-checkbox-list-item-padding-x: k-spacing(0) !default;
/// The vertical padding of the CheckBox list items
/// @group checkbox
$kendo-checkbox-list-item-padding-y: $kendo-list-md-item-padding-y !default;


// CheckBox ripple

/// The background color of the CheckBox' ripple.
/// @group checkbox
$kendo-checkbox-ripple-bg: $kendo-checkbox-checked-bg !default;
/// The opacity of the CheckBox' ripple.
/// @group checkbox
$kendo-checkbox-ripple-opacity: .25 !default;


@forward "@progress/kendo-theme-default/scss/checkbox/_variables.scss" with (
    $kendo-checkbox-border-width: $kendo-checkbox-border-width,
    $kendo-checkbox-sm-size: $kendo-checkbox-sm-size,
    $kendo-checkbox-md-size: $kendo-checkbox-md-size,
    $kendo-checkbox-lg-size: $kendo-checkbox-lg-size,
    $kendo-checkbox-sm-glyph-size: $kendo-checkbox-sm-glyph-size,
    $kendo-checkbox-md-glyph-size: $kendo-checkbox-md-glyph-size,
    $kendo-checkbox-lg-glyph-size: $kendo-checkbox-lg-glyph-size,
    $kendo-checkbox-sm-ripple-size: $kendo-checkbox-sm-ripple-size,
    $kendo-checkbox-md-ripple-size: $kendo-checkbox-md-ripple-size,
    $kendo-checkbox-lg-ripple-size: $kendo-checkbox-lg-ripple-size,
    $kendo-checkbox-sizes: $kendo-checkbox-sizes,
    $kendo-checkbox-bg: $kendo-checkbox-bg,
    $kendo-checkbox-text: $kendo-checkbox-text,
    $kendo-checkbox-border: $kendo-checkbox-border,
    $kendo-checkbox-hover-bg: $kendo-checkbox-hover-bg,
    $kendo-checkbox-hover-text: $kendo-checkbox-hover-text,
    $kendo-checkbox-hover-border: $kendo-checkbox-hover-border,
    $kendo-checkbox-checked-bg: $kendo-checkbox-checked-bg,
    $kendo-checkbox-checked-text: $kendo-checkbox-checked-text,
    $kendo-checkbox-checked-border: $kendo-checkbox-checked-border,
    $kendo-checkbox-indeterminate-bg: $kendo-checkbox-indeterminate-bg,
    $kendo-checkbox-indeterminate-text: $kendo-checkbox-indeterminate-text,
    $kendo-checkbox-indeterminate-border: $kendo-checkbox-indeterminate-border,
    $kendo-checkbox-focus-border: $kendo-checkbox-focus-border,
    $kendo-checkbox-focus-shadow: $kendo-checkbox-focus-shadow,
    $kendo-checkbox-focus-checked-border: $kendo-checkbox-focus-checked-border,
    $kendo-checkbox-focus-checked-shadow: $kendo-checkbox-focus-checked-shadow,
    $kendo-checkbox-disabled-bg: $kendo-checkbox-disabled-bg,
    $kendo-checkbox-disabled-text: $kendo-checkbox-disabled-text,
    $kendo-checkbox-disabled-border: $kendo-checkbox-disabled-border,
    $kendo-checkbox-disabled-checked-bg: $kendo-checkbox-disabled-checked-bg,
    $kendo-checkbox-disabled-checked-text: $kendo-checkbox-disabled-checked-text,
    $kendo-checkbox-disabled-checked-border: $kendo-checkbox-disabled-checked-border,
    $kendo-checkbox-invalid-bg: $kendo-checkbox-invalid-bg,
    $kendo-checkbox-invalid-text: $kendo-checkbox-invalid-text,
    $kendo-checkbox-invalid-border: $kendo-checkbox-invalid-border,
    $kendo-checkbox-indicator-type: $kendo-checkbox-indicator-type,
    $kendo-checkbox-glyph-font-family: $kendo-checkbox-glyph-font-family,
    $kendo-checkbox-checked-glyph: $kendo-checkbox-checked-glyph,
    $kendo-checkbox-indeterminate-glyph: $kendo-checkbox-indeterminate-glyph,
    $kendo-checkbox-checked-image: $kendo-checkbox-checked-image,
    $kendo-checkbox-indeterminate-image: $kendo-checkbox-indeterminate-image,
    $kendo-checkbox-label-margin-x: $kendo-checkbox-label-margin-x,
    $kendo-checkbox-list-spacing: $kendo-checkbox-list-spacing,
    $kendo-checkbox-list-item-padding-x: $kendo-checkbox-list-item-padding-x,
    $kendo-checkbox-list-item-padding-y: $kendo-checkbox-list-item-padding-y,
    $kendo-checkbox-ripple-bg: $kendo-checkbox-ripple-bg,
    $kendo-checkbox-ripple-opacity: $kendo-checkbox-ripple-opacity
);
