@use "../_globals.scss" as *;
@use "@progress/kendo-theme-core/scss/functions/index.import.scss" as *;
@use "sass:map";

@mixin kendo-utils--responsive-layout--col-gap() {

    // Columns gap
    $kendo-utils-col-gap: map.get( $kendo-utils, "gap" ) !default;

    @each $key, $val in $kendo-utils-col-gap {
        $_key: k-escape-class-name( $key );
        .k-col-gap-#{$_key} {
            --kendo-col-gap: #{$val};
        }
    }

}

@mixin kendo-utils--responsive-layout--col-gap--responsive($breakpoint) {

    // Columns gap responsive
    $kendo-utils-col-gap: map.get( $kendo-utils, "gap" ) !default;

    @each $key, $val in $kendo-utils-col-gap {
        $_key: k-escape-class-name( $key );
        .k-col-gap-#{$breakpoint}-#{$_key} {
            --kendo-col-gap: #{$val};
        }
    }
}


