@import "../resources/styles/variables.scss";
@import "../resources/styles/kendoui-fiba-theme";
@import "../resources/styles/custom-bootstrap";
@import "../resources/styles/global";

/* Style for loading page */

#app-loading {
  width: 100%;
  height: 100vh;
  background-color: black;
}

.loading-text {
  position: absolute;
  top: 8%;
  width: 100%;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 400 28px Verdana, Geneva, sans-serif;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading-image {
  height: 60%;
  width: 512px;
  max-height: 250px;
  background-size: auto 100%;
  background: black url("/static/fiba_logo_512x252.png") no-repeat center;
  animation: heartbeat 1.5s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.01);
  }
  30% {
    transform: scale(0.98);
  }
  45% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
