@use "sass:map";
@use "sass:string";
@use "@progress/kendo-theme-core/scss/index.import.scss" as *;
@use "@progress/kendo-theme-core/scss/functions/_map.import.scss" as *;

$kendo-theme-colors: () !default;

$kendo-font-sizes: () !default;

$kendo-util-colors-list: (
    app-surface,
    on-app-surface,
    subtle,
    surface,
    surface-alt,
    border,
    border-alt,
    base-subtle,
    base-emphasis,
    primary-subtle,
    primary,
    primary-emphasis,
    secondary-subtle,
    secondary,
    secondary-emphasis,
    tertiary-subtle,
    tertiary,
    tertiary-emphasis,
    info-subtle,
    info,
    info-emphasis,
    success-subtle,
    success,
    success-emphasis,
    warning-subtle,
    warning,
    warning-emphasis,
    error-subtle,
    error,
    error-emphasis,
    light-subtle,
    light,
    light-emphasis,
    dark-subtle,
    dark,
    dark-emphasis,
) !default;

$kendo-util-colors: () !default;
@each $name, $color in $kendo-colors {
    @each $util-color in $kendo-util-colors-list {
        @if ( $name == $util-color ) {
            $kendo-util-colors: map.merge( $kendo-util-colors, ( $name: $color) );
        }
    }
}

$kendo-spacing: () !default;

$kendo-sizing: () !default;

$kendo-border-radii: () !default;

$kendo-elevation: () !default;

$kendo-colors: () !default;

$kendo-enable-color-system: true !default;

$_default-kendo-fraction-classes: (
    '91-6': 91.6666%,
    '83-3': 83.3333%,
    '66-6': 66.6666%,
    '58-3': 58.3333%,
    '41-6': 41.6666%,
    '33-3': 33.3333%,
    '16-7': 16.6666%,
    '14-3': 14.2857%,
    '12-5': 12.5%,
    '11-1': 11.1111%,
    '9-1': 9.0909%,
    '8-3': 8.3333%
);

$kendo-fraction-classes: $_default-kendo-fraction-classes !default;
$kendo-fraction-classes: map.merge($_default-kendo-fraction-classes, $kendo-fraction-classes);

$_default-kendo-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;

$kendo-breakpoints: $_default-kendo-breakpoints !default;
$kendo-breakpoints: map.merge($_default-kendo-breakpoints, $kendo-breakpoints);

$_default-kendo-container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
) !default;

$kendo-container-max-widths: $_default-kendo-container-max-widths !default;
$kendo-container-max-widths: map.merge($_default-kendo-container-max-widths, $kendo-container-max-widths);

$kendo-utils: (

    // Accessibility
    "screen-readers": true,

    // Layout
    "aspect-ratio": (
        auto: auto,
        "1": 1,
        square: string.unquote("1 / 1"),
        video: string.unquote("16 / 9")
    ),
    "container": (),
    "columns": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        auto: auto
    ),
    "break-after": (),
    "break-before": (),
    "break-inside": (),
    "box-decoration-break": (),
    "box-sizing": (
        border: border-box,
        content: content-box
    ),
    "display": (
        none,
        contents,
        block,
        inline,
        inline-block,
        flex,
        inline-flex,
        grid,
        inline-grid,
        table,
        inline-table,
        table-row,
        table-cell,
        list-item
    ),
    "float": (
        left,
        right,
        none
    ),
    "clear": (
        left,
        right,
        both,
        none
    ),
    "isolation": (),
    "object-fit": (
        contain,
        cover,
        fill,
        scale-down,
        initial,
        none
    ),
    "object-position": (
        center: center,
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        top-left: top left,
        top-right: top right,
        bottom-left: bottom left,
        bottom-right: bottom right
    ),
    "overflow": (
        auto: auto,
        hidden: hidden,
        visible: visible,
        scroll: scroll,
        clip: clip
    ),
    "overscroll": (),
    "placement": map.merge( $kendo-spacing, (
        "-1": -1px
    )),
    "position": (
        static,
        relative,
        absolute,
        fixed,
        sticky
    ),
    "inset": (
        "0": 0,
        "1": 1px,
        "-1": -1px
    ),
    "visibility": (
        visible: visible,
        invisible: hidden,
        collapse: collapse
    ),
    "zindex": (
        "0": 0,
        "10": 10,
        "20": 20,
        "30": 30,
        "40": 40,
        "50": 50,
        auto: auto
    ),

    // Flex / Grid
    "flex-direction": (
        row: row,
        row-reverse: row-reverse,
        col: column,
        col-reverse: column-reverse
    ),
    "flex-wrap": (
        wrap,
        wrap-reverse,
        nowrap
    ),
    "flex": (
        "1": 1 1 0%,
        auto: 1 1 auto,
        initial: 0 1 auto,
        none: none
    ),
    "flex-grow": (
        DEFAULT: 1,
        "0": 0
    ),
    "flex-shrink": (
        DEFAULT: 1,
        "0": 0
    ),
    "flex-basis": $kendo-sizing,
    "order": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        first: -9999,
        last: 9999,
        none: 0
    ),
    "grid-template-columns": (
        "1": repeat( 1, minmax( 0, 1fr ) ),
        "2": repeat( 2, minmax( 0, 1fr ) ),
        "3": repeat( 3, minmax( 0, 1fr ) ),
        "4": repeat( 4, minmax( 0, 1fr ) ),
        "5": repeat( 5, minmax( 0, 1fr ) ),
        "6": repeat( 6, minmax( 0, 1fr ) ),
        "7": repeat( 7, minmax( 0, 1fr ) ),
        "8": repeat( 8, minmax( 0, 1fr ) ),
        "9": repeat( 9, minmax( 0, 1fr ) ),
        "10": repeat( 10, minmax( 0, 1fr ) ),
        "11": repeat( 11, minmax( 0, 1fr ) ),
        "12": repeat( 12, minmax( 0, 1fr ) ),
        none: none
    ),
    "grid-template-rows": (
        "1": repeat( 1, minmax( 0, 1fr ) ),
        "2": repeat( 2, minmax( 0, 1fr ) ),
        "3": repeat( 3, minmax( 0, 1fr ) ),
        "4": repeat( 4, minmax( 0, 1fr ) ),
        "5": repeat( 5, minmax( 0, 1fr ) ),
        "6": repeat( 6, minmax( 0, 1fr ) ),
        "7": repeat( 7, minmax( 0, 1fr ) ),
        "8": repeat( 8, minmax( 0, 1fr ) ),
        "9": repeat( 9, minmax( 0, 1fr ) ),
        "10": repeat( 10, minmax( 0, 1fr ) ),
        "11": repeat( 11, minmax( 0, 1fr ) ),
        "12": repeat( 12, minmax( 0, 1fr ) ),
        none: none
    ),
    "grid-column-start": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        "13": 13,
        auto: auto
    ),
    "grid-column-end": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        "13": 13,
        auto: auto
    ),
    "grid-column-span": (
        "1": span 1 / span 1,
        "2": span 2 / span 2,
        "3": span 3 / span 3,
        "4": span 4 / span 4,
        "5": span 5 / span 5,
        "6": span 6 / span 6,
        "7": span 7 / span 7,
        "8": span 8 / span 8,
        "9": span 9 / span 9,
        "10": span 10 / span 10,
        "11": span 11 / span 11,
        "12": span 12 / span 12,
        full: string.unquote("1 / -1"),
        auto: auto
    ),
    "grid-row-start": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        "13": 13,
        auto: auto
    ),
    "grid-row-end": (
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9,
        "10": 10,
        "11": 11,
        "12": 12,
        "13": 13,
        auto: auto
    ),
    "grid-row-span": (
        "1": span 1 / span 1,
        "2": span 2 / span 2,
        "3": span 3 / span 3,
        "4": span 4 / span 4,
        "5": span 5 / span 5,
        "6": span 6 / span 6,
        "7": span 7 / span 7,
        "8": span 8 / span 8,
        "9": span 9 / span 9,
        "10": span 10 / span 10,
        "11": span 11 / span 11,
        "12": span 12 / span 12,
        full: span 1 / span -1,
        auto: auto
    ),
    "grid-auto-flow": (
        row: row,
        col: column,
        dense: dense,
        row-dense: row dense,
        col-dense: column dense,
        unset: unset
    ),
    "grid-auto-columns": (
        auto: auto,
        min: min-content,
        max: max-content,
        fr: minmax( 0, 1fr ),
    ),
    "grid-auto-rows": (
        auto: auto,
        min: min-content,
        max: max-content,
        fr: minmax( 0, 1fr ),
    ),
    "gap": map.merge( $kendo-spacing, (
        // TODO: remove this extension once we fix docs
        xs: k-spacing(1),
        sm: k-spacing(2),
        md: k-spacing(3),
        lg: k-spacing(4),
        xl: k-spacing(6),
        thin: k-spacing(0.5),
        hair: k-spacing(1px)
    )),
    "align-content": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline,
        between: space-between,
        around: space-around,
        evenly: space-evenly
    ),
    "align-items": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline,
        self-start: self-start,
        self-end: self-end
    ),
    "align-self": (
        auto: auto,
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline,
        self-start: self-start,
        self-end: self-end
    ),
    "justify-content": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        left: left,
        right: right,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline,
        between: space-between,
        around: space-around,
        evenly: space-evenly
    ),
    "justify-items": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        self-start: self-start,
        self-end: self-end,
        left: left,
        right: right,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline
    ),
    "justify-self": (
        auto: auto,
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        self-start: self-start,
        self-end: self-end,
        baseline: baseline,
        first-baseline: first baseline,
        last-baseline: last baseline
    ),
    "place-content": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        baseline: baseline,
        between: space-between,
        around: space-around,
        evenly: space-evenly
    ),
    "place-items": (
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        self-start: self-start,
        self-end: self-end,
        baseline: baseline
    ),
    "place-self": (
        auto: auto,
        normal: normal,
        stretch: stretch,
        center: center,
        start: start,
        end: end,
        flex-start: flex-start,
        flex-end: flex-end,
        self-start: self-start,
        self-end: self-end,
        baseline: baseline
    ),
    "margin": k-map-merge(
        $kendo-spacing, k-map-negate($kendo-spacing),
        (
        // TODO: remove this extension once we fix docs
        xs: k-spacing(1),
        sm: k-spacing(2),
        md: k-spacing(3),
        lg: k-spacing(4),
        xl: k-spacing(6),
        thin: k-spacing(0.5),
        hair: k-spacing(1px),
        auto: auto
    )),
    "padding": map.merge( $kendo-spacing, (
        // TODO: remove this extension once we fix docs
        xs: k-spacing(1),
        sm: k-spacing(2),
        md: k-spacing(3),
        lg: k-spacing(4),
        xl: k-spacing(6),
        thin: k-spacing(0.5),
        hair: k-spacing(1px)
    )),
    "space-between": (),

    // Sizing
    "width": map.merge( $kendo-sizing, (
        screen: 100vw
    )),
    "min-width": (
        "0": 0,
        full: 100%,
        screen: 100vw,
        min: min-content,
        max: max-content,
        fit: fit-content
    ),
    "max-width": (
        none: none,
        "0": 0,
        full: 100%,
        screen: 100vw,
        min: min-content,
        max: max-content,
        fit: fit-content
    ),
    "height": map.merge( $kendo-sizing, (
        screen: 100vh
    )),
    "min-height": (
        "0": 0,
        full: 100%,
        screen: 100vh,
        min: min-content,
        max: max-content,
        fit: fit-content
    ),
    "max-height": (
        none: none,
        "0": 0,
        full: 100%,
        screen: 100vh,
        min: min-content,
        max: max-content,
        fit: fit-content
    ),

    // Typography
    "font-family": (),
    "font-size": (),
    "font-smoothing": (),
    "font-style": (
        italic: italic,
        non-italic: normal,
        oblique: oblique
    ),
    "font-weight": (),
    "font-variant-numeric": (),
    "letter-spacing": (),
    "line-height": (),
    "list-style-type": (
        none,
        disc,
        decimal
    ),
    "list-style-position": (),
    "text-align": (
        left,
        right,
        center,
        justify,
        start,
        end
    ),
    "text-color": map.merge( if( $kendo-enable-color-system, $kendo-util-colors, $kendo-theme-colors ), (
        "inherit": inherit,
        "current": currentColor,
        "transparent": transparent,
        "black": black,
        "white": white
    )),
    "text-decoration": (
        underline: underline,
        overline: overline,
        line-through: line-through,
        no-underline: none
    ),
    "text-decoration-width": (),
    "text-decoration-style": (),
    "text-decoration-color": (),
    "text-decoration-offset": (),
    "text-transform": (
        uppercase: uppercase,
        lowercase: lowercase,
        capitalize: capitalize,
        normal-case: none
    ),
    "text-overflow": (
        clip: clip,
        ellipsis: ellipsis
    ),
    "text-indent": (),
    "vertical-align": (
        baseline,
        top,
        middle,
        bottom,
        text-top,
        text-bottom,
        sub,
        super
    ),
    "white-space": (
        normal,
        nowrap,
        pre,
        pre-line,
        pre-wrap,
        break-spaces
    ),
    "word-break": (),

    // Background
    "background-clip": (
        border: border-box,
        padding: padding-box,
        content: content-box,
        text: text
    ),
    "background-color": map.merge( if( $kendo-enable-color-system, $kendo-util-colors, $kendo-theme-colors ), (
        "inherit": inherit,
        "transparent": transparent,
        "black": black,
        "white": white
    )),
    "background-position": (
        center: center,
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        top-left: top left,
        top-right: top right,
        bottom-left: bottom left,
        bottom-right: bottom right
    ),
    "background-size": (
        auto,
        cover,
        contain
    ),

    // Border
    "border-width": (
        DEFAULT: 1px,
        "0": 0,
        "2": 2px,
        "4": 4px,
        "8": 8px
    ),
    "border-style": (
        solid,
        dashed,
        dotted,
        double,
        // groove,
        // ridge,
        inset,
        outset,
        hidden,
        none
    ),
    "border-color": map.merge( if( $kendo-enable-color-system, $kendo-util-colors, $kendo-theme-colors ), (
        "inherit": inherit,
        "current": currentColor,
        "transparent": transparent,
        "black": black,
        "white": white
    )),
    "border-radius": $kendo-border-radii,
    "outline-width": (
        DEFAULT: 1px,
        "0": 0,
        "2": 2px,
        "4": 4px,
        "8": 8px
    ),
    "outline-style": (
        solid,
        dashed,
        dotted,
        double,
        // groove,
        // ridge,
        inset,
        outset,
        none
    ),
    "outline-color": map.merge( if( $kendo-enable-color-system, $kendo-util-colors, $kendo-theme-colors ), (
        "inherit": inherit,
        "current": currentColor,
        "transparent": transparent,
        "black": black,
        "white": white
    )),
    "outline-offset": (
        "0": 0,
        "1": 1px,
        "2": 2px,
        "4": 4px,
        "8": 8px
    ),

    // Effects
    "box-shadow": (),
    "box-shadow-color": (),
    "opacity": (
        "0": 0,
        "5": 0.05,
        "10": 0.1,
        "20": 0.2,
        "30": 0.3,
        "40": 0.4,
        "50": 0.5,
        "60": 0.6,
        "70": 0.7,
        "80": 0.8,
        "90": 0.9,
        "100": 1
    ),

    // Filter
    "backdrop-blur": (
        DEFAULT: blur(8px),
        none: blur(0),
        sm: blur(4px),
        md: blur(6px),
        lg: blur(12px)
    ),

    // Table
    "border-collapse": (
        collapse,
        separate
    ),
    "table-layout": (
        auto,
        fixed
    ),

    // Transition

    // Transform
    "flip": true,
    "scale": (
        "0": 0,
        "0.25": .25,
        "0.5": .5,
        "0.75": .75,
        "1": 1,
        "1.2": 1.25,
        "1.5": 1.5,
        "2": 2
    ),
    "rotate": (
        "0": 0deg,
        "45": 45deg,
        "90": 90deg,
        "135": 135deg,
        "180": 180deg,
        "225": 225deg,
        "270": 270deg,
        "315": 315deg
    ),
    "translate": k-map-remove( $kendo-sizing, "auto", "min", "max", "fit" ),
    "skew": (
        "0": 0deg,
        "1": 1deg,
        "2": 2deg,
        "3": 3deg,
        "4": 4deg,
        "5": 5deg,
        "6": 6deg,
        "7": 7deg,
        "8": 8deg,
        "9": 9deg,
        "10": 10deg,
        "11": 11deg,
        "12": 12deg
    ),
    "origin": (
        center: center,
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        top-left: top left,
        top-right: top right,
        bottom-left: bottom left,
        bottom-right: bottom right
    ),


    // Interactivity
    "accent-color": (),
    "appearance": (
        none,
        auto
    ),
    "cursor": (
        none,
        auto,
        default,
        context-menu,
        help,
        pointer,
        progress,
        wait,
        cell,
        crosshair,
        text,
        vertical-text,
        alias,
        copy,
        move,
        no-drop,
        not-allowed,
        e-resize,
        n-resize,
        ne-resize,
        nw-resize,
        s-resize,
        se-resize,
        sw-resize,
        w-resize,
        ew-resize,
        ns-resize,
        nesw-resize,
        nwse-resize,
        col-resize,
        row-resize,
        all-scroll,
        zoom-in,
        zoom-out,
        grab,
        grabbing
    ),
    "caret-color": (),
    "pointer-events": (
        none,
        auto
    ),
    "resize": (
        DEFAULT: both,
        none: none,
        x: horizontal,
        y: vertical
    ),
    "scroll-behavior": (
        auto,
        smooth
    ),
    "scroll-snap-type": (
        none: none,
        snap-x: x var(--k-scroll-snap-strictness),
        snap-y: y var(--k-scroll-snap-strictness),
        snap-both: both var(--k-scroll-snap-strictness)
    ),
    "scroll-snap-align": (
        start,
        end,
        center,
        none
    ),
    "scroll-snap-stop": (
        normal,
        always
    ),
    "scroll-margin": $kendo-spacing,
    "scroll-padding": $kendo-spacing,
    "touch-action": (
        none,
        auto,
        pan-x,
        pan-left,
        pan-right,
        pan-y,
        pan-up,
        pan-down,
        pinch-zoom,
        manipulation
    ),
    "user-select": (
        none,
        auto,
        text,
        all,
        contain
    ),
    "will-change": (
        auto: auto,
        scroll: scroll-position,
        contents: contents,
        transform: transform
    ),
    "fill-stroke": (
        none: none,
        inherit: inherit,
        current: currentColor,
        "transparent": transparent,
        "black": black,
        "white": white
    )
) !default;
