@use "../core/_index.scss" as *;

// Imageeditor
$kendo-image-editor-margin-y: null !default;
$kendo-image-editor-margin-x: null !default;
$kendo-image-editor-padding-y: null !default;
$kendo-image-editor-padding-x: null !default;

$kendo-image-editor-border-width: 1px !default;
$kendo-image-editor-font-size: var( --kendo-font-size, inherit )  !default;
$kendo-image-editor-line-height: var( --kendo-line-height, normal ) !default;
$kendo-image-editor-font-family: var( --kendo-font-family, inherit ) !default;

$kendo-image-editor-content-border-width: 1px !default;

$kendo-image-editor-action-pane-padding-y: k-spacing(8) !default;
$kendo-image-editor-action-pane-padding-x: k-spacing(4) !default;
$kendo-image-editor-action-pane-width: if( $kendo-image-editor-content-border-width == null, 240px, calc( 240px + #{$kendo-image-editor-content-border-width} ) ) !default;

$kendo-image-editor-crop-border-width: 1px !default;
$kendo-image-editor-crop-border-style: dashed !default;

$kendo-image-editor-resize-handle-margin-x: k-spacing(1px) !default;
$kendo-image-editor-resize-handle-margin-y: k-spacing(1px) !default;
$kendo-image-editor-resize-handle-border-width: 0 2px 2px 0 !default;
$kendo-image-editor-resize-handle-size: 15px !default;

$kendo-image-editor-bg: $kendo-component-bg !default;
$kendo-image-editor-text: $kendo-component-text !default;
$kendo-image-editor-border: $kendo-component-border !default;

$kendo-image-editor-content-bg: #c6c6c6 !default;
$kendo-image-editor-content-text: null !default;
$kendo-image-editor-content-border: null !default;

$kendo-image-editor-action-pane-bg: $kendo-component-bg !default;
$kendo-image-editor-action-pane-text: null !default;
$kendo-image-editor-action-pane-border: null !default;

$kendo-image-editor-crop-bg: null !default;
$kendo-image-editor-crop-text: null !default;
$kendo-image-editor-crop-border: white !default;

$kendo-image-editor-crop-overlay-bg: rgba( black, .3 ) !default;


@forward "@progress/kendo-theme-default/scss/imageeditor/_variables.scss" with (
    $kendo-image-editor-margin-y: $kendo-image-editor-margin-y,
    $kendo-image-editor-margin-x: $kendo-image-editor-margin-x,
    $kendo-image-editor-padding-y: $kendo-image-editor-padding-y,
    $kendo-image-editor-padding-x: $kendo-image-editor-padding-x,
    $kendo-image-editor-border-width: $kendo-image-editor-border-width,
    $kendo-image-editor-font-size: $kendo-image-editor-font-size,
    $kendo-image-editor-line-height: $kendo-image-editor-line-height,
    $kendo-image-editor-font-family: $kendo-image-editor-font-family,
    $kendo-image-editor-content-border-width: $kendo-image-editor-content-border-width,
    $kendo-image-editor-action-pane-padding-y: $kendo-image-editor-action-pane-padding-y,
    $kendo-image-editor-action-pane-padding-x: $kendo-image-editor-action-pane-padding-x,
    $kendo-image-editor-action-pane-width: $kendo-image-editor-action-pane-width,
    $kendo-image-editor-crop-border-width: $kendo-image-editor-crop-border-width,
    $kendo-image-editor-crop-border-style: $kendo-image-editor-crop-border-style,
    $kendo-image-editor-resize-handle-margin-x: $kendo-image-editor-resize-handle-margin-x,
    $kendo-image-editor-resize-handle-margin-y: $kendo-image-editor-resize-handle-margin-y,
    $kendo-image-editor-resize-handle-border-width: $kendo-image-editor-resize-handle-border-width,
    $kendo-image-editor-resize-handle-size: $kendo-image-editor-resize-handle-size,
    $kendo-image-editor-bg: $kendo-image-editor-bg,
    $kendo-image-editor-text: $kendo-image-editor-text,
    $kendo-image-editor-border: $kendo-image-editor-border,
    $kendo-image-editor-content-bg: $kendo-image-editor-content-bg,
    $kendo-image-editor-content-text: $kendo-image-editor-content-text,
    $kendo-image-editor-content-border: $kendo-image-editor-content-border,
    $kendo-image-editor-action-pane-bg: $kendo-image-editor-action-pane-bg,
    $kendo-image-editor-action-pane-text: $kendo-image-editor-action-pane-text,
    $kendo-image-editor-action-pane-border: $kendo-image-editor-action-pane-border,
    $kendo-image-editor-crop-bg: $kendo-image-editor-crop-bg,
    $kendo-image-editor-crop-text: $kendo-image-editor-crop-text,
    $kendo-image-editor-crop-border: $kendo-image-editor-crop-border,
    $kendo-image-editor-crop-overlay-bg: $kendo-image-editor-crop-overlay-bg
);
